const React = require('react')
const { silentAuth } = require('./src/utils/auth')

class SessionCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  handleCheckSession = () => {
    this.setState({ loading: false })
  }

  componentDidMount() {
    silentAuth(this.handleCheckSession)
  }

  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    )
  }
}

exports.wrapRootElement = ({ element }) => {
  return <SessionCheck>{element}</SessionCheck>
}

exports.onClientEntry = () => {
  return new Promise((resolve, reject) => {
    window.__polyfillio__ = () => {
      resolve()
    }

    const features = []

    if (!("Intl" in window)) {
      const locale = window.location.pathname.split("/")[1]
      features.push(`Intl.~locale.${locale}`)
    }

    if (!("fetch" in window)) {
      features.push("fetch")
    }

    if (features.length) {
      const s = document.createElement("script")
      s.src = `https://cdn.polyfill.io/v2/polyfill.min.js?features=${features.join(
        ","
      )}&rum=1&flags=always,gated&callback=__polyfillio__`
      s.async = true
      s.onerror = reject
      document.head.appendChild(s)
    } else {
      resolve()
    }
  })
}
